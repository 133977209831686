.container {
  height: 40vh;
  min-height: 20rem;
  padding: 1rem;
  margin-top: 3rem;
  border-radius: 10px;
  background-color: var(--bg-accent);
  flex-direction: column;
  gap: 1rem;
}

.container p {
  width: 80%;
  font-size: 1.75rem;
}
