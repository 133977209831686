.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.title {
  font-size: 2rem;
  color: var(--text-primary);
}

.form form {
  width: 100%;
  margin: 0;
  padding: 3rem 1rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  background-color: var(--bg-accent);
  transition: all 0.3s ease-in-out;
}

.form form:hover {
  box-shadow: 0 25px 25px black;
}

.form-control {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
}

.form form label {
  font-size: 1.5rem;
}

.form i {
  margin-left: 0.75rem;
}

.form form input {
  width: 60%;
  padding: 1rem;
  border: 1px solid #eee;
  border-radius: 10px;
  font-size: 1.1rem;
}

@media (max-width: 800px) {
  .form-control {
    width: 70%;
    flex-direction: column;
  }

  .form form input {
    width: auto;
  }
}
