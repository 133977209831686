.item {
  width: 30%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.name {
  width: 75%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-size: 1.5rem;
}

.name div {
  color: var(--text-primary);
  transition: all 0.3s;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.name div:hover {
  cursor: pointer;
  transform: translateY(-5px);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.name div::-webkit-scrollbar {
  height: 8px;
  margin-top: 1rem;
  background-color: white;
  border-radius: 10px;
}

.name div::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 10px;
}

.name div::-webkit-scrollbar-thumb:active {
  background-color: black;
}

.name input {
  min-width: 0;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 10px;
  background: white;
  font-size: 1.1rem;
  font-family: inherit;
  transition: all 0.3s;
}

.name input:hover {
  cursor: pointer;
  transform: translateY(-5px);
}

.name button {
  padding: 0.5rem 0.75rem;
  border: none;
  border-radius: 0.5rem;
  transition: all 0.3s;
}

.name button:hover {
  cursor: pointer;
  transform: translateY(-5px);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
