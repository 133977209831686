.container {
  width: 100%;
  height: 40vh;
  max-height: 30rem;
  min-height: 20rem;
  padding: 1rem;
  border-radius: 10px;
  background-color: var(--bg-accent);
}

.container img {
  max-height: 100%;
  max-width: 100%;
}
