.form {
  width: 75%;
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.form label {
  font-size: 1.5rem;
  color: var(--text-primary);
}

.control {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.form input {
  padding: 1rem 1rem;
  border: none;
  border-radius: 10px;
  background: white;
  font-size: 1.1rem;
  font-family: inherit; 
  transition: all 0.3s;
}

.form input:hover,
.form input:focus {
  transform: translateY(-5px);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.form button {
  margin: auto;
  padding: 0.5rem 0.75rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 1.6rem;
  transition: all 0.3s;
}

.form button:hover {
  cursor: pointer;
  transform: translateY(-5px);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

@media (max-width: 432px) {
  .control {
    flex-direction: column;
  }
}
