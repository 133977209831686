.navbar {
	width: 100%;
	padding: 1rem;
	background-color: var(--bg-accent);
	border-radius: 10px;
	display: flex;
	flex-direction: row;
	gap: 1rem;
	align-items: center;
}

.logo {
	display: none;
	min-width: 8rem;
	margin: 0;
	text-align: center;
	font-size: 1.25rem;
}

.navbar ul {
	width: 100%;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.5rem;
}

.hamburger {
	border: none;
	display: none;
	color: var(--text-accent);
	background-color: transparent;
}

.hamburger:hover {
	cursor: pointer;
}


@media (max-width: 768px) {
	.logo {
		display: block;
	}

	.hamburger {
		margin-right: 0.25rem;
		margin-left: auto;
		display: block;
	}

	.navbar ul {
		--width: 15rem;
		position: fixed;
		width: var(--width);
		top: 0;
		left: calc(-1 * var(--width));
		height: 100vh;
		padding: 2rem 1rem;
		border-radius: 0 1rem 1rem 0;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		background-color: var(--bg-secondary);
		transform: translateX(0);
		transition: transform 0.3s ease-in-out;
		z-index: 30;
	}

	.navbar ul.display {
		transform: translateX(var(--width));
	}
}