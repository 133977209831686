.images {
  position: relative;
  left: 50%;
  width: 200%;
  max-width: 75vw;
  margin: 3rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  transform: translateX(-50%)
}
