.container {
  height: 40vh;
  min-height: 20rem;
  padding: 1rem;
  border-radius: 10px;
  background-color: var(--bg-accent);
  flex-direction: column;
  gap: 1rem;
}

.container button {
  margin: 0 auto;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  color: var(--text-accent);
  font-size: 1.2rem;
  transition: all 0.3s ease-in-out;
}

.container button:hover {
  padding: 0.75rem 3rem;
  color: var(--text-primary);
  background-color: var(--bg-secondary);
  cursor: pointer;
  transform: translateY(-5px);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 12px -7px;
}

.container p {
  width: 80%;
  font-size: 1.75rem;
}
