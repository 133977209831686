.nav-item {
	text-decoration: none;
	list-style-type: none;
}

.nav-item:last-child {
	margin-left: auto;
}

.nav-item a {
	--border-color: var(--text-accent);
	--icon-rotate: 0deg;
	padding: 0.5rem;
	border: 0.1rem solid var(--border-color);
	border-radius: 10px;
	display: flex;
	flex-direction: row;
	gap: 0.75rem;
	transition: all 0.2s ease-in;
}

.nav-item:last-child a {
	color: var(--text-primary);
	background-color: var(--bg-secondary);
}

.nav-item a:hover {
	--border-color: transparent;
	--icon-rotate: 90deg;
	background-color: var(--bg-accent-light);
	box-shadow: rgba(0, 0, 0, 0.3) 0px 6px 3px -3px inset;
}

.nav-item a:active {
	--icon-rotate: 360deg;
}

.icon {
	font-size: 1.25rem;
	font-family: FontAwesome;
}

.rotate i {
	transform: rotate(var(--icon-rotate));
	transition: all 0.2s ease-in-out;
}

.nav-item:last-child a:hover {
	border: 1.5px solid var(--bg-secondary);
	color: var(--text-accent);
	background-color: var(--bg-accent);
	box-shadow: none;
	transform: none;
}

@media (max-width: 768px) {
	.nav-item {
		width: 100%;
	}

	.nav-item a {
		padding: 0.75rem;
		color: var(--text-primary);
		background-color: transparent;
	}

	.nav-item a:hover {
		color: var(--text-accent);
		background-color: var(--bg-accent);
		box-shadow: none;
	}
}