.search {
  width: 100%;
  padding: 1rem;
  font-size: 20px;
  border-radius: 10px;
  border: 0;
  text-align: left;
  transition: all 0.3s ease;
}

.search:active,
.search:hover,
.search:focus {
  transform: translateY(-5px);
}
